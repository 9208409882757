import React from 'react'
import OwlCarousel from 'react-owl-carousel';  
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Contacto from './Contacto'

export function Servicios() {
    return (
        <div>Servicios</div>
    )
}

export function Consultoria() {
    return(
        <div>
            <section class="page-title" style={{backgroundimage: 'url(assets/images/foto_consultoria.jpg)'}}>
                <div class="auto-container">
                    <div class="content-box">
                        <div class="content-wrapper">
                            <div class="title">
                                <h1>Desarrollo Organizacional</h1>
                            </div>
                            <ul class="bread-crumb clearfix">
                                <li>
                                Impulsa el potencial de tu equipo para un éxito duradero.
                                </li>
                            </ul>
                        </div>                    
                    </div>
                </div>
            </section>

            <section class="sidebar-page-container">
                <div class="auto-container">
                    <div class="row">
                        <div class="col-lg-8 content-side">
                            <div class="blog-single-post">
                                <h3>Claves para el Éxito Organizacional: Evaluación y Bienestar</h3>
                                <div class="text">
                                    <p>
                                    En un entorno empresarial cada vez más competitivo, el éxito de las organizaciones depende de su capacidad para adaptarse y mejorar continuamente. En este artículo, exploramos dos áreas fundamentales para el desarrollo organizacional: Diagnóstico y Evaluación del Desempeño y Bienestar y Cultura Organizacional. A través de estos pilares, cualquier empresa puede optimizar su rendimiento, fortalecer su equipo y alcanzar sus objetivos estratégicos.
                                    </p>
                                </div>
                                <div class="image"><img src="assets/images/close-up-people-working-office.jpg" alt=""/></div>
                                <blockquote>“ La clave para el éxito de una empresa no solo radica en identificar problemas, sino en aprovechar las fortalezas para impulsar el crecimiento. ”</blockquote>

                                <h3>Diagnóstico y Evaluación del Desempeño</h3>
                                <div class="text">
                                    <p>
                                    Un Diagnóstico Organizacional es esencial para detectar problemas y oportunidades de mejora, permitiendo ajustar la estructura y procesos de la empresa. Luego, a través de la Evaluación de Desempeño, medimos indicadores clave para valorar el rendimiento de los empleados y establecer un Sistema de Recompensas que motive y mejore los resultados.
                                    </p>
                                </div>

                                <h3>Bienestar y Cultura Organizacional</h3>
                                <div class="text">
                                    <p>
                                    Los Programas de Bienestar Social mejoran la calidad de vida de los empleados, elevando su salud física, mental y financiera. Junto con una sólida Cultura Organizacional, que alinea valores y expectativas, y el análisis del Clima Laboral, fomentamos un ambiente positivo y motivador para alcanzar un alto rendimiento.
                                    <br/><br/>
                                    El éxito empresarial se logra cuidando tanto los procesos como a las personas, creando un entorno donde el equipo pueda desarrollarse plenamente.   
                                    </p>
                                </div>
                            </div>
                        </div>
                        <aside class="col-lg-4 sidebar">
                            <div class="blog-sidebar">
                                <div class="widget widget_categories" style={{marginBottom:'0px'}}>
                                    <h3 class="widget-title">Nuestros Beneficios</h3>
                                    <div class="widget-content">
                                        <ul class="categories-list clearfix">
                                            <li><a href="#">Reducción de rotación y ausentismo</a></li>
                                            <li><a href="#">Incremento en la productividad</a></li>
                                            <li><a href="#">Aumento en la motivación del equipo</a></li>
                                            <li><a href="#">Capacitación enfocada en el personal más destacado</a></li>
                                            <li><a href="#">Bienestar integral de los colaboradores</a></li>
                                            <li><a>Mejor operatividad y eficiencia</a></li>
                                            <li><a>Optimiza la efectividad de tu organización</a></li>
                                            <li><a>Mayor satisfacción en el entorno laboral</a></li>
                                            <li><a>Mejora la competitividad en el mercado</a></li>
                                            <li><a>Liderazgo más eficiente</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </aside>
                    </div>
                </div>
            </section>

            <Contacto/>

            {/* <section class="testimonial-section-two">
                <div class="sec-bg" style={{backgroundimage: 'url(assets/images/background/bg-6.jpg)'}}></div>
                <div class="auto-container">
                    <div class="sec-title light style-two text-center">
                        <h2>Preguntas Frecuentes</h2>
                    </div>
                    <div class="theme_carousel owl-theme owl-carousel" data-options='{"loop": true, "margin": 0, "autoheight":true, "lazyload":true, "nav": true, "dots": true, "autoplay": true, "autoplayTimeout": 6000, "smartSpeed": 300, "responsive":{ "0" :{ "items": "1" }, "600" :{ "items" : "2" }, "768" :{ "items" : "2" } , "992":{ "items" : "3" }, "1200":{ "items" : "3" }}}'>
                        <div class="slide">
                            <div class="testimonial-block-three">
                                <div class="inner-box">
                                    <div class="quote"><span class="flaticon-quote"></span></div>
                                    <div class="author-title"><p style={{textAlign: 'center'}}><h4>¿Tengo que detener el día laboral de mi empresa o se puede hacer a la par? </h4></p></div>
                                        <div class="author-title"><p style={{textAlign: 'left'}}>Un consultor se involucra en la cultura y el comportamiento de tu empresa. La comunicación regular es clave para obtener información precisa.</p></div>
                                </div>
                            </div>
                        </div>
                        <div class="slide">
                            <div class="testimonial-block-three">
                                <div class="inner-box">
                                    <div class="quote"><span class="flaticon-quote"></span></div>
                                    <div class="author-title"><p style={{textAlign: 'center'}}><h4>¿Esto garantiza que mi empresa va a mejorar? </h4></p></div>
                                        <div class="author-title"><p style={{textAlign: 'left'}}>Nosotros somos facilitadores para llegar a los resultados en sinergia con el equipo de tu empresa para que los cambios sean significativos. </p></div>
                                </div>
                            </div>
                        </div>
                        <div class="slide">
                            <div class="testimonial-block-three">
                                <div class="inner-box">
                                    <div class="quote"><span class="flaticon-quote"></span></div>
                                    <div class="author-title"><p style={{textAlign: 'center'}}><h4>¿Cuánto cuesta?</h4></p></div>
                                        <div class="author-title"><p style={{textAlign: 'left'}}>Depende del servicio y del número de colaboradores de tu empresa. ¡Contáctanos!</p></div>
                                </div>
                            </div>
                        </div>
                        <div class="slide">
                            <div class="testimonial-block-three">
                                <div class="inner-box">
                                    <div class="quote"><span class="flaticon-quote"></span></div>
                                    <div class="author-title"><p style={{textAlign: 'center'}}><h4>¿En cuánto tiempo me entregan resultados acerca del status de mi empresa?</h4></p></div>
                                        <div class="author-title"><p style={{textAlign: 'left'}}>Una semana después del levantamiento de información. </p></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
        </div>
    )
}

export function Cobranza() {
    return(
        <div>
            <section class="page-title" style={{backgroundImage: 'url(assets/images/cobranza.jpg)'}}>
                <div class="auto-container">
                    <div class="content-box">
                        <div class="content-wrapper">
                            <div class="title">
                                <h1>Estabilidad Financiera Sin Complicaciones</h1>
                            </div>
                            <ul class="bread-crumb clearfix">
                                <li>Soluciona tu deuda con transparencia y confianza, sin estrés ni tecnicismos.</li>
                            </ul>
                        </div>                    
                    </div>
                </div>
            </section>

            <section class="about-section">
                <div class="auto-container">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="image-block">
                                <div class="image-one wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1500ms"><div class="image-box"><img class="lazy-image owl-lazy" src="assets/images/pagar_deuda.jpg" data-src="assets/images/resource/image-5.jpg" alt=""/></div></div>
                                <div class="image-two wow fadeInLeft" data-wow-delay="300ms" data-wow-duration="1500ms"><div class="image-box"><img class="lazy-image owl-lazy" src="assets/images/pagar.jpg" data-src="assets/images/resource/image-4.jpg" alt=""/></div></div>
                                <div class="logo"><img src="assets/images/logo.png" alt="" style={{width:'80px'}}/></div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="sec-title">
                                <h2>Quiero Pagar <br/> Mi Deuda</h2>
                                <div class="text-decoration">
                                    <span class="left"></span>
                                    <span class="right"></span>
                                </div>
                                <div class="text">Con nosotros, disfrutarás de beneficios que harán el proceso más fácil y transparente.</div>
                            </div>
                            <div class="text-block">
                                <h5>Planes Personalizados</h5>
                                <ul>
                                    <li>Diseño a medida: Creamos un plan de pago adaptado a ti.</li>
                                    <li>Transparencia: Tu dinero siempre visible.</li>
                                    <li>Descuentos: Negociamos el mejor descuento en tu deuda.</li>
                                </ul>
                            </div>
                            <div class="text-block">
                                <h5>Gestión Profesional</h5>
                                <ul>
                                    <li>Recuperación efectiva: Te ayudamos a recuperar tu estabilidad financiera.</li>
                                    <li>Cobro legal: Implementamos buenas prácticas en el proceso.</li>
                                </ul>
                            </div>
                            <div class="text-block">
                                <h5>Acompañamiento Constante</h5>
                                <ul>
                                    <li>Soporte continuo: Estamos contigo hasta el final.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="process-section-two pt-0">
                <div class="auto-container">
                    <div class="sec-title style-two text-center">
                        <br/>
                        <h2>¿Comó Funciona?</h2>
                    </div>
                    <div class="process-wrapper">
                        <div class="row">
                            <div class="col-lg-3 col-md-6 process-block-two">
                                <div class="inner-box">
                                    <h4>Analizamos tu caso.</h4>
                                    <br/>
                                    <div class="icon"><i class="flaticon-permission"></i><span class="step-count">01</span></div>
                                    <div class="text">De esta manera elaboramos una estrategia adecuada a tu bolsillo y necesidades.</div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6 process-block-two">
                                <div class="inner-box">
                                    <h4>Creamos un plan de pago.</h4>
                                    <br/>
                                    <div class="icon"><i class="flaticon-discussion"></i><span class="step-count">02</span></div>
                                    <div class="text">Tú nos dices cuánto puedes pagar y nosotros nos encargamos de hacerlo realidad.</div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6 process-block-two">
                                <div class="inner-box">
                                    <h4>Negociamos que pagues la menor cantidad.</h4>
                                    <div class="icon"><i class="flaticon-business-and-finance-1"></i><span class="step-count">03</span></div>
                                    <div class="text">No quitaremos el dedo del renglón hasta conseguir el mejor descuento.</div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6 process-block-two">
                                <div class="inner-box">
                                    <h4>Liquidamos</h4>
                                    <br/>
                                    <div class="icon"><i class="flaticon-money"></i><span class="step-count">04</span></div>
                                    <div class="text">Liquidamos tu deuda para que puedas tener nuevamente estabilidad financiera.</div>
                                </div>
                            </div>
                        </div>
                    </div>                
                </div>
            </section>

            <section class="projects-section-three" style={{paddingTop:'0px'}}>
                <div class="auto-container">
                    <div class="sec-title style-four text-center">
                        <h2>Nuestra Experiencia</h2>
                        <span class="text-decoration-three"></span>
                    </div>
                    <div class="row isotope-block">
                        <div class="project-block-three col-lg-3 col-md-6">
                            <div class="inner-box">
                                <div class="image"><img src="assets/images/house-bills-elements-arrangement.jpg" alt=""/></div>
                                <div class="overlay-content">
                                    <div class="content">
                                        <div class="category"><em>Financia tu hogar fácilmente.</em></div>
                                        <div class="text">Hipotecas</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="project-block-three col-lg-3 col-md-6">
                            <div class="inner-box">
                                <div class="image"><img src="assets/images/credito_patrimonial.jpg" alt=""/></div>
                                <div class="overlay-content">
                                    <div class="content">
                                        <div class="category"><em>Apoya tu patrimonio personal.</em></div>
                                        <div class="text">Créditos Patrimoniales</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="project-block-three col-lg-3 col-md-6">
                            <div class="inner-box">
                                <div class="image"><img src="assets/images/tdc.jpg" alt=""/></div>
                                <div class="overlay-content">
                                    <div class="content">
                                        <div class="category"><em>Facilita tus compras diarias.</em></div>
                                        <div class="text">Tarjetas de Crédito</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="project-block-three col-lg-3 col-md-6">
                            <div class="inner-box">
                                <div class="image"><img src="assets/images/pymes.jpg" alt=""/></div>
                                <div class="overlay-content">
                                    <div class="content">
                                        <div class="category"><em>Impulsa tu negocio ahora.</em></div>
                                        <div class="text">Créditos para Pymes</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="services-details" style={{paddingTop:'0px'}}>
                <div class="auto-container">
                    <div class="row">
                        <div class="col-lg-8 content-side">
                            <div class="blog-single-post">
                                <h3>Optimización de la Gestión de Clientes con CRM</h3>
                                <div class="text">
                                    <p>Nuestros <b>sistemas CRM</b> son soluciones clave para optimizar la gestión de relaciones con tus clientes y lograr un control efectivo sobre cuentas e interacciones. Personalizamos esta tecnología para <b>satisfacer las necesidades específicas de tu negocio</b>, ofreciendo módulos como el seguimiento de visitas a través de dispositivos móviles para mantener a tu equipo siempre conectado; un módulo de administración personalizada que se adapta a tu forma de trabajar; un módulo de análisis y reportes que facilita la toma de decisiones informadas; un módulo para la gestión de comunicaciones telefónicas que mejora la interacción con tus clientes; y un módulo para la captura y registro de visitas, asegurando que cada actividad sea monitoreada de manera efectiva. Con nuestro CRM, estarás equipado para llevar tu negocio al siguiente nivel.</p>
                                </div>
                                <div class="image"><img src="assets/images/crm.jpg" alt=""/></div>
                            </div>              
                        </div>
                        <aside class="col-lg-4">
                            <div class="service-sidebar">
                                <div class="widget widget_brochur" style={{backgroundImage: 'url(assets/images/background/bg-27.jpg)'}}>
                                    <div class="widget-content">
                                        <div class="icon"><img src="assets/images/icons/icon-60.png" alt=""/></div>
                                        <h5>Aviso de Privacidad</h5>
                                        <h4>(Deudores)</h4>
                                        <a href="assets/docs/aviso_de_privacidad_deudores.pdf" target="_blank"><i class="flaticon-right"></i>Descargar (616 kb)</a>
                                    </div>
                                </div>
                                <div class="widget widget_contact" style={{backgroundImage: 'url(assets/images/background/gente-trabajando-call-center.jpg)'}}>
                                    <div style={{position: 'absolute', top: '0', left: '0', width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)'}}></div>
                                    <div class="widget-content">
                                        <img src="assets/images/icons/icon-55.png" alt=""/>
                                        <h4>¿Necesitas nuestra ayuda?</h4>
                                        <div class="phone-number"><a href="tel:5533356846">(55) 3335-6846 </a></div>
                                        <div class="phone-number"><a href="tel:5534005243">(55) 3400-5243</a></div>
                                        <div class="email"><a href="mailto:contacto@pasivosgws.com.mx">contacto@pasivosgws.com.mx</a></div>
                                        <div class="link-btn"><a href="#" class="theme-btn btn-style-one">
                                            <span class="btn-title" href="/contacto">CONTACTO</span>
                                        </a></div>
                                    </div>
                                </div>
                            </div>
                        </aside>
                    </div>
                </div>
            </section>

            <Contacto/>
        </div>
    )
}

export default Servicios