import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Secciones/Home';
import { Header, Footer } from './Widgets/Widgets';
import { Consultoria, Cobranza} from './Secciones/Servicios';
import { Contacto, ContactoCobranza} from './Secciones/Contacto';

function App() {
  return (
    <Router>
      <div className="page-wrapper">
        <Header/>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/consultoria-en-desarrollo-organizacional" element={<Consultoria />} />
            <Route path="/cobranza-judicial-y-extrajudicial" element={<Cobranza />} />
            <Route path="/contacto" element={<Contacto />} />
          </Routes>
        <Footer/>
      </div>
    </Router>
  );
}

export default App;
