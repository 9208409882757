import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import Swal from 'sweetalert2';

export function Contacto() {

    const [message, setMessage] = useState('');

    emailjs.init('8aU3gTS8MHAFyUWo0');
    // Validación para el campo de mensaje (solo letras, números, acentos, signos de puntuación)
    const validateMessage = (msg) => {
        const regex = /^[\w\sáéíóúÁÉÍÓÚñÑ.,;:!?()¿¡'"-]*$/;
        return regex.test(msg);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!validateMessage(message)) {
        Swal.fire({
            icon: 'error',
            title: 'Mensaje inválido',
            text: 'El mensaje no debe contener enlaces ni etiquetas HTML.',
        });
        return;
        }

        const btn = document.querySelector('.theme-btn');
        btn.disabled = true;
        btn.querySelector('.btn-title').textContent = 'Enviando...';

        const serviceID = 'default_service';
        const templateID = 'template_5pzwemi';

        emailjs.sendForm(serviceID, templateID, e.target)
        .then(() => {
            btn.disabled = false;
            btn.querySelector('.btn-title').textContent = 'Enviar';
            Swal.fire({
            icon: 'success',
            title: 'Su email ha sido enviado exitosamente',
            html: 'Nos comunicaremos con usted a la brevedad.<br><p style="font-size:14px;">Acabamos de mandarle un correo respuesta a su bandeja de entrada, revísela por favor.</p>',
            });
        }, (err) => {
            btn.disabled = false;
            btn.querySelector('.btn-title').textContent = 'Enviar';
            Swal.fire({
            icon: 'error',
            title: 'Error al enviar',
            text: 'Hubo un problema al enviar tu mensaje. Inténtalo de nuevo más tarde.',
            });
        });
    };

    return (
        <div>
            <section class="contact-section style-five">
                <div class="auto-container">
                    <div class="row m-0">
                        <div class="col-lg-6 left-column" style={{backgroundImage: 'url(assets/images/cropped-image-businessman-sitting-by-table-cafe-analyzing-indicators-laptop-computer-while-talking-by-smartphone-focus-man.jpg)'}}>
                            <div class="inner-container">
                                <div class="wrapper-box">
                                    <div class="sec-title light">
                                        <h2>¿Tienes un caso? <br/> Envianos un mensaje</h2>
                                        <div class="text-decoration">
                                            <span class="left"></span>
                                            <span class="right"></span>
                                        </div>
                                    </div>
                                    <div class="author-box">
                                        <h4 style={{color: '#fff'}}>¿Tienes una pregunta?</h4>
                                        <div class="phone-numer"><a href="tel:5533356846" style={{color: '#c1a051'}}><i class="flaticon-phone" style={{color: '#c1a051'}}></i>  
                                        (55) 3335-6846</a></div>
                                    </div>
                                </div> 
                            </div>                                               
                        </div>
                        <div class="col-lg-6 right-column" style={{backgroundImage: 'url(assets/images/background/bg-23.jpg)'}}>
                            <div class="inner-container">
                                <div class="contact-form-box">
                                    <form id='form' className="contact-form" onSubmit={handleSubmit}>
                                        <div className="row">
                                            <div className="col-md-12 form-group">
                                            <input type="text" name="name" id='name' placeholder="Ingrese su Nombre Completo" required />
                                            </div>
                                            <div className="col-md-6 form-group">
                                            <input type="email" name="email" id='email' placeholder="Ingrese su correo electrónico" required />
                                            </div>
                                            <div className="col-md-6 form-group">
                                            <input type="text" name="phone" id='phone' placeholder="Teléfono" required maxLength={10} />
                                            </div>
                                            <div className="col-md-12 form-group">
                                            <input type="text" name="subject" id='subject' placeholder="Asunto" required />
                                            </div>
                                            <div className="col-md-12 form-group">
                                            <textarea 
                                                name="message" 
                                                id='message' 
                                                placeholder="Mensaje" 
                                                value={message} 
                                                onChange={(e) => setMessage(e.target.value)} 
                                                required
                                            />
                                            </div>
                                            <div className="col-md-12 form-group">
                                            <button className="theme-btn btn-style-one" type="submit" name="submit-form">
                                                <span className="btn-title">Enviar</span>
                                            </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>                            
                            </div>                        
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}


export default Contacto