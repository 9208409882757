import React from 'react'
import Contacto from './Contacto'

function Home() {
    return (
        <div>
            <section class="banner-section-two">
                <div class="shape-one"><img src="assets/images/shape/shape-11.png" alt=""/></div>
                <div class="shape-two"><img src="assets/images/shape/shape-12.png" alt=""/></div>
                <div class="shape-three"><img src="assets/images/shape/shape-13.png" alt=""/></div>
                <div class="shape-four"><img src="assets/images/shape/shape-14.png" alt=""/></div>
                <div class="shape-five"><img src="assets/images/shape/shape-15.png" alt=""/></div>
                <div class="shape-six"><img src="assets/images/shape/shape-16.png" alt=""/></div>
                <div class="auto-container">
                    <div class="content-box">
                        <div>
                            <h2>¿Quiénes somos?</h2>
                            <div class="text">Somos una empresa dedicada a guiar, capacitar y acompañar a nuestros clientes en la creación de un ambiente laboral  saludable y productivo. <br/> Nos enfocamos en brindar soluciones personalizadas que promuevan el bienestar y el crecimiento dentro del entorno de trabajo.</div>
                            <div class="btn-box">
                                <a href="#" class="theme-btn btn-style-one"><span class="btn-title">Conocer más <i class="fa fa-caret-right"></i></span></a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="feature-section-six">
                <div class="auto-container">
                    <div class="wrapper-box">
                        <div class="row">
                            <div class="col-lg-6 feature-block-six">
                                <div class="inner-box">
                                    <div class="icon"><img src="assets/images/icons/icon-2.png" alt=""/></div>
                                    <h4>Misión</h4>
                                    <div class="text">Impulsamos el desarrollo empresarial de México con soluciones especializadas y de calidad.</div>
                                </div>
                            </div>
                            <div class="col-lg-6 feature-block-six">
                                <div class="inner-box">
                                    <div class="icon"><img src="assets/images/icons/icon-3.png" alt=""/></div>
                                    <h4>Visión</h4>
                                    <div class="text">Buscamos liderar a nivel internacional el crecimiento integral de nuestras filiales.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="about-section-four">
                <div class="auto-container">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="image-block">
                                <div class="image-one wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1500ms"><div class="image-box"><img class="lazy-image owl-lazy" src="assets/images/business-man-financial-inspector-secretary-making-report-calculating-checking-balance-internal-revenue-service-inspector-checking-document-audit-concept.jpg" data-src="assets/images/resource/image-28.jpg" alt=""/></div></div>
                                <div class="image-two wow fadeInLeft" data-wow-delay="300ms" data-wow-duration="1500ms"><div class="image-box"><img class="lazy-image owl-lazy" src="assets/images/cheerful-young-executive-looking-camera.jpg" data-src="assets/images/resource/image-29.jpg" alt=""/></div></div>
                                <div class="logo"><img src="assets/images/logo.png" alt="" style={{width:'80px'}}/></div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="sec-title style-four">
                                <h2>Nuestros Servicios</h2>
                                <span class="text-decoration-three"></span>
                            </div>
                            <div class="text">
                                <p>Con nuestra Consultoría en Desarrollo Organizacional, identificamos fortalezas y necesidades de tu equipo para crear un ambiente laboral más eficiente y productivo.</p>
                                <p>Además, con nuestro servicio de Cobranza Judicial y Extrajudicial, ofrecemos soluciones claras y directas, ayudándote a recuperar tu estabilidad financiera sin complicaciones ni sorpresas</p>
                            </div>
                            <div class="link-btn"><a href="#" class="theme-btn btn-style-one"><span class="btn-title">Conocer más <i class="fa fa-caret-right"></i></span></a></div>
                        </div>
                    </div>
                </div>
            </section>

            <Contacto/>
        </div>
    )
}

export default Home